export {onBoardAndInTheAirMainView as default};
import aaBusinessFood from '../../../img/AABusinessFood.jpg';
import flightAttendant from '../../../img/FemaleFAServingBusiness.jpg';
import firstOverhead from '../../../img/FirstOverhead.jpg';

var fltAttendantVar = '<img src='+flightAttendant+' class="img-fluid" />';
var aaBusinessFoodVar = '<img src='+aaBusinessFood+' class="img-fluid" />';
var firstOverheadVar = '<img src='+firstOverhead+' class="img-fluid" />';
var spclCharacter = "From Main Cabin all the way to our luxurious Flagship Suite® that's fully lie-flat with aisle access, we can take you from origin to destination - not just in safety but in comfort too.";
var inFlightContent = '<p class="text-style-1">Wi-Fi and Internet</p><p class="text-style-2">For all of our planes configured with 2 or more cabins of service, Wi-Fi is available and complimentary. Many planes even offer high-speed options that let you enjoy all of your activities on your device as if you were back on the ground.</p><p class="text-style-1">Inflight Entertainment</p><p class="text-style-2">On all planes equipped with Wi-Fi, inflight entertainment is also available and complimentary, including hundreds of movie, tv show, music, and game options – streamed either to seatback screen or on your mobile device. No matter how you enjoy it, we’ve got entertainment to last you on even the longest of journeys.</p>';
var overheadContent = '<p class="text-style-1">Seats</p><p class="text-style-2">Our expansive fleet provides you numerous options when it comes to both the number of seats and types. '+spclCharacter +'</p><p class="text-style-1">Cabin Products</p><p class="text-style-2">We offer a variety of on-board cabin products, like our signture ammenity kits created to pamper you with travel essentials from leading skincare and lifestyle brands. Try adding some of our Casper pillows, duvets, and even some cozy pajamas created exclusively for us. With American and Casper partnered together, you’re now cleared to enjoy that perfect night’s sleep – or just something cozy to watch the latest movies with – your choice.</p>';
var businessFoodContent = '<p class="text-style-1">Our Menu</p><p class="text-style-2">Our menu selections offer a balance of modern interpretations and traditional flavors for a simple, yet elevated dining experience. Charter menus are specifically curated with your preferences and needs in mind. Depending on where you want us to take you, you can experience some of our destination-inspired entrees or dishes crafted by award winning chefs from the James Beard Foundation.</p><p class="text-style-1">Tailored to your tastes</p><p class="text-style-2">With a wide range of aircraft types to choose from comes a wide range of options for onboard dining. Our menu tailoring includes different tiers of service to make customization easy for you. Whether you are craving snacks, a light sandwich or a four-course meal, we have options for you. Enjoy complimentary beverages with accompaniment on all flights with us. From there, you can select from our catalogue of appetizers, entrees, desserts, snacks and more.</p>';
export function onBoardAndInTheAirMainView(){
    return '<div><p class="section-header">Onboard and in the air</p><div class="FAQ"><div><div class="row"><div class="col-md-6" style="margin-top:10px;">'+fltAttendantVar+'</div><div class="col-md-6" style="margin-top:20px;">'+inFlightContent+'</div></div><div class="row"><div class="col-md-6 float-lg-right" style="margin-top:30px; padding-left:15px;">'+firstOverheadVar+'</div><div class="col-md-6 float-lg-left" style="margin-top:30px;">'+overheadContent+'</div></div><div class="row"><div class="col-md-6" style="margin-top:30px; ">'+aaBusinessFoodVar+'</div><div class="col-md-6" style="margin-top:25px;">'+businessFoodContent+'</div></div></div></div></div>';
}