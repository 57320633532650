import cardLayout from './CardLayout.js';
import LandingImage from '../../../img/Landing.jpg';
import '../ChartersLanding/Mainview.css';
// import FlightAttendant from '../../../img/FemaleFAServingBusiness.jpg';
import pyt from './PlanningYourTrip.js';
import throughoutTheJourneyMainView from './ThroughoutTheJourney.js';
import onTheGroundMainView from './OnTheGround.js';
import onBoardAndInTheAirMainView from './OnBoardAndInTheAir.js';


function submenuAndCardClickHandler(sectionValue, cardClick) {
    //scroll to top for all sub pages
    // document.getElementById("chartersHeaderDiv").scrollIntoView({ 
    //     behavior: 'smooth',
    //     block: "start"
    //   });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    //close the submenu section if its open
    if(document.querySelector('a[aria-expanded="true"]') && cardClick==="card") document.getElementById("caretupsubmenu").click();
    if (sectionValue === 'pytSection') {
        document.getElementById("mainview").innerHTML = pyt();       
    } else if (sectionValue === 'ttjSection') {
        document.getElementById("mainview").innerHTML = throughoutTheJourneyMainView();
    } else if (sectionValue === 'otgSection') {
        document.getElementById("mainview").innerHTML = onTheGroundMainView();
    } else if (sectionValue === 'obitaSection') {
        document.getElementById("mainview").innerHTML = onBoardAndInTheAirMainView();
    }

}


var landingImage = '<img src=' + LandingImage + ' class="img-fluid backgroundImg" />';
// var fltAttendant = '<img src='+FlightAttendant+' class="img-fluid fltAttendantImg" />';
var mainViewDiv = '<section id="mainview"></section>';
var landingMainViewSectionDiv = '<div> <div> ' + landingImage + ' </div><div class="maincontent"> <div class="row body"><div class="row bodycontent"><p><strong>American Airlines</strong> offers you the ability to build your own customized travel itinerary by chartering one of our aircraft to fit your schedule. As the world’s largest airline, we stand ready to provide you with numerous aircraft options to take you to thousands of destinations.</p></div><div class="featuresandbenefits"> <p>Features and Benefits</p></div><div class="row"><ul class="paragraph"> <li> The full use of any plane between 50 and 304 seats with sole discretion as to how many passengers you wish to fly.<br><br> </li><li>The ability to customize your origin, destination, arrival or departure time, catering, and depending on your travel needs, private handling and screening as well. <br><br></li><li> Rest easy knowing that each charter complies with all the same safety and regulatory requirements of a Part 121 commercial flight. <br><br></li><li> Enjoy the comfort of cost-predictability with all-inclusive pricing. <br><br></li><li> A dedicated charter representative to consult with before, during, and after your journey.</ul></div><div class="row requestacharterlinkcardscroll"><div class="col-md-6"><a target="_blank" href="https://c03.keysurvey.com/f/116913/d778/">Request a charter <i class="icon-newpage"></i></a></div><div class="col-md-6 d-xs-none scrolltocards"><a id="scrolltocards" href="#cards"><i class="fa fa-chevron-down"></i></a></div></div></div><section id="cards"><div class="container cards"><div class="charterexperience"><p>Charter experience</p></div>' + cardLayout() + '</div><div></section></div></div>';

document.getElementById("mainContainer").innerHTML += mainViewDiv;
document.getElementById("mainview").innerHTML = landingMainViewSectionDiv;


//adding event listeners for all the submenu items
if (document.getElementById("pytSubmenu")) document.getElementById("pytSubmenu").addEventListener("click", function (e) { e.preventDefault(); submenuAndCardClickHandler('pytSection') });
if (document.getElementById("ttjSubmenu")) document.getElementById("ttjSubmenu").addEventListener("click", function (e) { e.preventDefault(); submenuAndCardClickHandler('ttjSection') });
if (document.getElementById("otgSubmenu")) document.getElementById("otgSubmenu").addEventListener("click", function (e) { e.preventDefault(); submenuAndCardClickHandler('otgSection') });
if (document.getElementById("obaitaSubmenu")) document.getElementById("obaitaSubmenu").addEventListener("click", function (e) { e.preventDefault(); submenuAndCardClickHandler('obitaSection') });

//adding even listeners for all cards
document.getElementById("PYTCard").addEventListener("click", function (e) { e.preventDefault(); submenuAndCardClickHandler('pytSection', "card") });
document.getElementById("TTJCard").addEventListener("click", function (e) { e.preventDefault(); submenuAndCardClickHandler('ttjSection', "card") });
document.getElementById("OTGCard").addEventListener("click", function (e) { e.preventDefault(); submenuAndCardClickHandler('otgSection', "card") });
document.getElementById("OBAITACard").addEventListener("click", function (e) { e.preventDefault(); submenuAndCardClickHandler('obitaSection', "card") });

document.getElementById("scrolltocards").addEventListener("click", function (e) { e.preventDefault();  document.getElementById("cards").scrollIntoView()});
document.getElementById("hamburgerMenu").addEventListener("click" , function(){
    if(document.querySelector('a[aria-expanded="false"]') && this.getAttribute('aria-expanded') === "false") {
        document.getElementById("caretupsubmenu").click();
    } else if(!document.querySelector('a[aria-expanded="false"]')){
        document.getElementById("charter-experience").click();
    }
 });


