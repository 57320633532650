
import PilotTarmac from '../../../img/PilotTarmac.jpg';
import Towermonitoringgatetraffic from '../../../img/Towermonitoringgatetraffic.jpg';
import BaggageTarmac from '../../../img/BaggageTarmac.jpg';
import FlagshipFirstoverhead from '../../../img/FlagshipFirstoverhead.jpg';
import '../ChartersLanding/Card.css'
export { populateCard as default };



var img = '';
var alt = '';

export function populateCard(value) {
    if (value === 'TTJCard') {
        img = Towermonitoringgatetraffic;
        alt = "Throughout the journey";
    } else if (value === 'OTGCard') {
        img = BaggageTarmac;
        alt = "On the ground";
    } else if (value === 'OBAITACard') {
        img = FlagshipFirstoverhead;
        alt = "Onboard and in the air";
    } else if (value === 'PYTCard') {
        img = PilotTarmac;
        alt = "Planning your trip";
    }
    var cardsImg = '<img class="card-img-top img-fluid" src=' + img + '>';
    return '<a href="#" id='+ value +'> <div class="col-md-6 col-sm-6"><div class="card"><div class="cards-img">' + cardsImg + '<div class="img-overlay"> </div></div> <div class="card-body"><p class="cardheader"> ' +alt+ '</p><p class="learnmore" href="#">Learn More &#187</p></div></div></div></a>';

}

